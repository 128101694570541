<template>
  <v-app app>
    <TopBar></TopBar>

    <v-main class="grey lighten-3">
      <div
        :class="$store.getters.getIsPlay ? 'message flash' : 'message'"
        v-if="$store.getters.getIsPlay"
      >
        <v-container class="tips">
          <v-alert :type="$store.getters.getTips.type">{{
            $store.getters.getTips.message
          }}</v-alert>
        </v-container>
      </div>

      <v-container>
        <v-row>
          <v-col cols="3">
            <Nav></Nav>
          </v-col>
          <v-col>
            <v-sheet min-height="90vh" rounded="lg" max-width="80vw">
              <router-view></router-view>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import Footer from "@/components/Footer.vue";
import Nav from "@/components/Nav.vue";

export default {
  components: { TopBar, Footer, Nav },
  created() {},
  methods: {},
};
</script>
<style>
.message {
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  z-index: 100;
  transition: all 3s linear;
}
.flash {
  animation: tip 3s linear;
}
.tips {
  width: fit-content;
}
@keyframes tip {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}
</style>
