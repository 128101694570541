import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeView,
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "article",
        component: () => import("@/components/ArticleList.vue"),
        meta: { title: "欢迎来到GinBlog" },
      },
      {
        path: "detail/:id",
        name: "detail",
        component: () => import("@/components/Details.vue"),
        meta: { title: "文章详情" },
        props: true,
      },
    ],
  },
  {
    path: "/*",
    redirect: "/index",
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.fullPath == from.fullPath) {
    next();
    return;
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
