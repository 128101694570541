import axios from "axios";
import Vue from "vue";

// let baseURL = "http://localhost:3000/api/v1";
let baseURL = "http://106.14.254.173:9001/api/v1";
axios.defaults.baseURL = baseURL;
axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${window.localStorage.getItem(
    "token"
  )}`;
  return config;
});
Vue.prototype.$http = axios;
export { baseURL };
