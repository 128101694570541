<template>
  <v-app-bar app color="indigo darken-2">
    <v-avatar size="40" color="white" class="mx-12">
      <img src="@/assets/avatar.png" alt="" />
    </v-avatar>

    <v-container class="py-0 fill-height justify-center" fill-height>
      <v-btn text color="white" @click="cateChange(0)">首页</v-btn>
      <v-btn
        v-for="item in cateList"
        :key="item.id"
        text
        color="white"
        @click="cateChange(item.id)"
        >{{ item.name }}</v-btn
      >
    </v-container>

    <v-spacer></v-spacer>
    <v-responsive max-width="260">
      <v-text-field
        class="white"
        dense
        flat
        hide-details
        rounded
        solo-inverted
        color="white"
        v-model="value"
        placeholder="回车发起搜索"
        @keyup="search"
        :append-icon="'mdi-magnify'"
      >
      </v-text-field>
    </v-responsive>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      cateList: [],
    };
  },
  created() {
    this.getCateList();
  },
  methods: {
    //获取分类列表
    async getCateList() {
      const { data: res } = await this.$http.get("category");
      if (res.status != 200) return this.$message("error", res.message);
      this.cateList = res.data;
    },
    cateChange(cid) {
      this.$store.commit("changeCid", cid);
      this.$store.commit("changeActionType", 1);
      if (location.pathname != "/") {
        this.$router.push("/");
      }
      this.$bus.$emit("changeCid", cid);
    },
    search(e) {
      if (e.key === "Enter") {
        this.$store.commit("changeActionType", 2);
        if (location.pathname != "/") {
          this.$router.push("/");
        }
        this.$bus.$emit("searchArt", this.value);
      }
    },
  },
};
</script>