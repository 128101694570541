import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cid: 0,
    actionType: 1,
    isPlay: false,
    tips: {
      type: "success",
      message: "",
    },
  },
  getters: {
    getCid: (state) => {
      return state.cid;
    },
    getActionType(state) {
      return state.state;
    },
    getIsPlay(state) {
      return state.isPlay;
    },
    getTips(state) {
      return state.tips;
    },
  },
  mutations: {
    changeCid(state, step) {
      state.cid = step;
    },
    changeActionType(state, step) {
      state.actionType = step;
    },
    hide(state) {
      state.isPlay = false;
    },
    show(state, step) {
      state.isPlay = true;
      state.tips.type = step.type;
      state.tips.message = step.message;
    },
  },
  actions: {},
  modules: {},
});
