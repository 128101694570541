import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/http";
import moment from "moment";

Vue.filter("dateformat", function (indate, outdate) {
  return moment(indate).format(outdate);
});
Vue.prototype.$bus = new Vue();

Vue.prototype.$message = (type, message) => {
  let end = setTimeout(function () {}, 10000);
  for (let i = 1; i <= end; i++) {
    clearTimeout(i);
  }
  store.commit("hide");

  store.commit("show", { type, message });
  const task = setTimeout(() => {
    store.commit("hide");
  }, 2900);
};
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
