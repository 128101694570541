<template>
  <v-card class="mx-auto" max-width="300">
    <v-img :src="profile.img">
      <v-card-title>
        <v-col align="center">
          <v-avatar size="130" color="grey">
            <img :src="profile.avatar" alt="" />
          </v-avatar>
          <div class="ma-4 white--text">{{ profile.name }}</div>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
    </v-img>
    <v-card-title>About Me:</v-card-title>
    <v-card-text>{{ profile.desc }}</v-card-text>

    <v-divider color="indigo"></v-divider>
    <v-list nav dense>
      <v-list-item v-if="profile.qqchat">
        <v-list-item-icon class="ma-3">
          <v-icon color="blue darken-2">{{ "mdi-qqchat" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.qqchat
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.wechat">
        <v-list-item-icon class="ma-3">
          <v-icon color="green darken-2">{{ "mdi-wechat" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.wechat
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.weibo">
        <v-list-item-icon class="ma-3">
          <v-icon color="orange darken-2">{{ "mdi-sina-weibo" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.weibo
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.bili">
        <v-list-item-icon class="ma-3">
          <v-icon color="pink darken-2">{{ "mdi-youtube" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.bili
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.email">
        <v-list-item-icon class="ma-3">
          <v-icon color="orange darken-2">{{ "mdi-email" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.email
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.github">
        <v-list-item-icon class="ma-3">
          <v-icon color="black darken-2">{{ "mdi-github" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.github
        }}</v-list-item-content>
      </v-list-item>
      <v-list-item v-if="profile.star">
        <v-list-item-icon class="ma-3" @click="point(1)">
          <v-icon color="yellow darken-2">{{ "mdi-star" }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="grey--text">{{
          profile.star
        }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      profile: {
        id: 1,
        name: "",
        desc: "",
        qqchat: "",
        wechat: "",
        weibo: "",
        bili: "",
        github: "",
        email: "",
        img: "",
        avatar: "",
        star: 0,
      },
    };
  },
  created() {
    this.getProfileInfo(1);
  },
  methods: {
    //获取个人信息
    async getProfileInfo(id) {
      const { data: res } = await this.$http.get(`/profile/${id}`);
      if (res.status != 200) return this.$message("error", res.message);
      this.profile = res.data;
    },
    async point(id) {
      const { data: res } = await this.$http.get(`profile/point/${id}`);
      if (res.status != 200) return this.$message("error", res.message);
      this.$message("success", "感谢投币三连，祝你升官发财");
      this.getProfileInfo(1);
    },
  },
};
</script>
